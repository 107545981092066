/* You can add global styles to this file, and also import other style files */
body {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eee;
}
.nrg-table-header {
  font-size: 14px;
}
.nrg-table-body {
  font-size: 14px;
}
.sr-only {
  font-size: 14px;
  font-weight: bold;
}
.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
}

.navbar-brand {
  font-size: 18px;
}

.error-box {
  margin-bottom: 10px;
}

.nav-item {
  font-size: 14px;
}

.navbar-text {
  font-size: 14px;
}
.form-select-sm {
  font-size: 14px;
}

.yellow-on-black {
  color: mediumpurple;
  font-weight: bold;
  /*background-color: black;*/
}

.special-blue {
  color: blue;
  font-weight: bold;
  background-color: yellow;
}

.special-red {
  color: red;
  font-weight: bold;
  background-color: yellow;
}

.special-purple {
  color: mediumpurple;
  font-weight: bold;
  background-color: yellow;
}

html, body { height: 100%; }

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -20px; /* the bottom margin is the negative value of the footer's height */
}
.footer {
  height: 20px; /* .push must be the same height as .footer */
  font-size: 12px;
}

textarea {
  width: 100%;
}

.td-inv-item-desc {
  max-width: 350px;
}


body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
